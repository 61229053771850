import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, Image } from "react-native";
import * as Location from "expo-location";
import * as Permissions from "expo-permissions";
import { Notifications } from "expo";
import { Audio } from "expo-av";
import useInterval from "@use-it/interval";
import styled from "styled-components/native";
import ConfettiCannon from "react-native-confetti-cannon";
import * as Haptics from "expo-haptics";

import TrainIndicator from "./TrainIndicator";
import { useColorScheme } from "react-native-appearance";
import CohubLogo from "./CohubLogo";

// const PUSH_REGISTRATION_ENDPOINT = "https://trainzz.ngrok.io/token";
const PUSH_REGISTRATION_ENDPOINT = "https://train.cohub.com/api/token";

export default function TrainDetector() {
  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sensorData, setSensorData] = useState<any>(null);
  const [showStats, setShowStats] = useState(false);
  const [bothClosed, setBothClosed] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useInterval(() => {
    if (showConfetti) {
      setShowConfetti(false);
    }
  }, 8000);

  const randomizeConfetti = () => {
    console.log(showConfetti);

    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    if (Math.random() * 100 > 80) {
      console.log("inside the math random");
      if (!showConfetti) {
        setShowConfetti(true);
        console.log({ showConfetti: showConfetti });
      }
    }
  };

  // const trainDetected = (sensorData && sensorData.train >= 0.9) || (sensorData && sensorData.signal >= 0.9);
  const fourthClosed = sensorData && sensorData.fourthClosed;
  const chestnutClosed = sensorData && sensorData.chestnutClosed;
  const maintenanceMode = sensorData && sensorData.maintenanceMode;

  const colorMode = useColorScheme();

  const _getLocationAsync = async () => {
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    if (status !== "granted") {
      setErrorMessage("Permission to access location was denied");
    }

    let location = await Location.getCurrentPositionAsync({});
    setLocation(location);
  };

  const registerForPushNotificationsAsync = async () => {
    const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
    if (status !== "granted") {
      return;
    }
    let token = await Notifications.getExpoPushTokenAsync();
    // Defined in following steps
    await fetch(PUSH_REGISTRATION_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
    }),
    });

    Notifications.addListener(handleNotification);
  };

  const handleNotification = (notification) => {
    // alert(JSON.stringify(notification));
  };

  useEffect(() => {
    // _getLocationAsync();
  });

  useEffect(() => {
    registerForPushNotificationsAsync();

    // Location.startGeofencingAsync("train-detector-fence", [
    //   {
    //     identifier: "fourth",
    //     latitude: 36.142245,
    //     longitude: -86.764855,
    //     radius: 1206,
    //     notifyOnEnter: true,
    //     notifyOnExit: true
    //   }
    // ]);
  }, []);

  useEffect(() => {
    if (fourthClosed && chestnutClosed) {
      setBothClosed(true);
    } else {
      setBothClosed(false);
    }
  }, [fourthClosed, chestnutClosed]);

  // useEffect(() => {
  //   if (bothClosed) {
  //     playSound();
  //   }
  // }, [bothClosed]);

  useInterval(() => {
    // fetch("https://trainzz.ngrok.io/status", { mode: "no-cors" })
    fetch("https://train.cohub.com/api/status")
      .then((res) => res.json())
      .then((json) => setSensorData(json));
  }, 3000);

  const setTextColor = () => {
    if (colorMode === "dark") {
      return "#FAFAFA";
    } else {
      return "#201F1F";
    }
  };

  const playSound = async () => {
    const soundObject = new Audio.Sound();
    try {
      await soundObject.loadAsync(require("./assets/sounds/train-horn.mp3"));
      soundObject.playAsync();
    } catch (error) {
      setErrorMessage("Error playing sound");
    }
  };

  const trainText = () => {
    if (maintenanceMode) {
      return (
        <>
          <Text
            style={{ fontSize: 32, textAlign: "center", color: setTextColor() }}
          >
            Maintenance Mode
          </Text>
          <Text style={{ color: setTextColor() }}>Be back shortly.</Text>
        </>
      );
    }

    if (bothClosed) {
      return (
        <>
          <Text
            style={{ fontSize: 32, textAlign: "center", color: setTextColor() }}
          >
            Train!
          </Text>
          <Text style={{ color: setTextColor() }}>
            You probably wanna go a different way.
          </Text>
        </>
      );
    } else if (fourthClosed && !chestnutClosed) {
      return (
        <Text
          style={{ fontSize: 32, textAlign: "center", color: setTextColor() }}
        >
          4th Ave. is blocked. {fourthClosed}
        </Text>
      );
    } else if (chestnutClosed && !fourthClosed) {
      return (
        <Text
          style={{ fontSize: 32, textAlign: "center", color: setTextColor() }}
        >
          Chestnut St. is blocked. {chestnutClosed}
        </Text>
      );
    } else {
      return (
        <Text
          style={{ fontSize: 32, textAlign: "center", color: setTextColor() }}
        >
          Coast is clear.
        </Text>
      );
    }
  };

  const asPercentage = (
    value: number | null | undefined,
    precision: number
  ) => {
    if (!value) {
      return 0;
    }
    return (value * 100).toFixed(precision);
  };

  return (
    <Container colorMode={colorMode}>
      {showConfetti && (
        <ConfettiCannon
          count={80}
          origin={{ x: 0, y: 0 }}
          fadeOut={true}
          colors={["#63B05A", "#D83E50", "#F1C84B"]}
        />
      )}
      <View style={{ marginTop: 32 }}>
        <CohubLogo
          chestnutClosed={chestnutClosed}
          fourthClosed={fourthClosed}
        ></CohubLogo>
      </View>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <TouchableOpacity onPress={() => randomizeConfetti()}>
          <TrainIndicator
            fourthClosed={fourthClosed}
            chestnutClosed={chestnutClosed}
            maintenanceMode={maintenanceMode}
          ></TrainIndicator>
        </TouchableOpacity>
        <View style={{ marginTop: 32 }}></View>
        {trainText()}
      </View>
      <TouchableOpacity
        onPress={() => setShowStats(!showStats)}
        style={{ marginBottom: 24 }}
        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
      >
        <Text
          style={{
            fontSize: 10,
            textAlign: "center",
            marginBottom: 16,
            color: setTextColor(),
          }}
        >
          peek under the hood
        </Text>
        {showStats && (
          <>
            {/* <Text>SPEED: {location?.coords.speed}</Text>
            <Text>HEADING: {location?.coords.heading}</Text>
            <Text>LAT: {location?.coords.latitude}</Text>
            <Text>LNG: {location?.coords.longitude}</Text>
            <Text>IN RANGE: {state.inRange.toString()}</Text> */}
            <Text style={{ color: setTextColor() }}>
              4th Train P: {asPercentage(sensorData?.fourthStatus.train, 5)}%
            </Text>
            <Text style={{ color: setTextColor() }}>
              4th Signal P: {asPercentage(sensorData?.fourthStatus.signal, 5)}%
            </Text>
            <Text style={{ color: setTextColor() }}>
              Chestnut Train P:{" "}
              {asPercentage(sensorData?.chestnutStatus.train, 5)}%
            </Text>
            <Text style={{ color: setTextColor() }}>
              Chestnut Signal P:{" "}
              {asPercentage(sensorData?.chestnutStatus.signal, 5)}%
            </Text>
            {/* <Text>Version: 1.0.10</Text> */}
          </>
        )}
      </TouchableOpacity>
    </Container>
  );
}

interface IContainerProps {
  colorMode: string;
}

const Container = styled.View<IContainerProps>`
  height: 100%;
  flex: 1;
  background-color: ${(props) =>
    props.colorMode === "dark" ? "#201F1F" : "#FFFFFF"};
  align-items: center;
  justify-content: space-between;
  padding-top: 36;
  padding-bottom: 36;
  padding-left: 36;
  padding-right: 36;
`;

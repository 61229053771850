import React, { useState, useEffect } from "react";
import styled from "styled-components/native";

interface IProps {
  bgColor?: string;
  fourthClosed: boolean;
  chestnutClosed: boolean;
  maintenanceMode: boolean;
}

type CircleProps = Required<Pick<IProps, "bgColor">>;

const Circle = styled.View<CircleProps>`
  border-radius: 1000;
  background-color: ${props => props.bgColor};
  height: 160;
  width: 160;
`;

export default function TrainIndicator({
  fourthClosed,
  chestnutClosed,
  maintenanceMode
}: IProps) {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if (showConfetti) {
        setShowConfetti(false);
      }
    }, 5000);
    return () => {
      setShowConfetti(false);
    };
  }, [showConfetti]);

  const circleColor = () => {
    if (maintenanceMode) {
      return "hsl(212, 14%, 35%)";
    }

    if (fourthClosed && !chestnutClosed) {
      return "hsl(45, 86%, 62%)";
    } else if (!fourthClosed && chestnutClosed) {
      return "hsl(45, 86%, 62%)";
    } else if (fourthClosed && chestnutClosed) {
      return "hsl(353, 66%, 55%)";
    } else {
      return "hsl(114, 35%, 52%)";
    }
  };

  return <Circle bgColor={circleColor()}></Circle>;
}

import React from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import TrainDetector from "./TrainDetector";
import * as TaskManager from "expo-task-manager";
import * as Location from "expo-location";
import { Notifications } from "expo";
import { AppearanceProvider } from "react-native-appearance";

// TaskManager.defineTask(
//   "train-detector-fence",
//   async ({ data: { eventType, region }, error }: any) => {
//     if (error) {
//       // check `error.message` for more details.
//       // console.log(error.message);
//       // alert(error.message);
//       return;
//     }
//     if (eventType === Location.GeofencingEventType.Enter) {
//       // console.log("You've entered region:", region);
//       // alert("you entered the region");
//       store.dispatch({ type: "IN_RANGE" });
//       // Notifications.presentLocalNotificationAsync({
//       //   title: "Hello there",
//       //   body: "Just testing"
//       // });
//       // if (!soundObject._loaded) {
//       //   await soundObject.loadAsync(require("./assets/sample.mp3"));
//       // }

//       // await soundObject.playAsync();
//     } else if (eventType === Location.GeofencingEventType.Exit) {
//       // console.log("You've left region:", region);
//       // alert("you left the region");
//       store.dispatch({ type: "NOT_IN_RANGE" });
//     }
//   }
// );

export default function App() {
  return (
    <AppearanceProvider>
      <Provider store={store}>
        <TrainDetector />
      </Provider>
    </AppearanceProvider>
  );
}

interface IAction {
  type: string;
  payload?: any;
}

export interface IAppState {
  inRange: boolean;
  trainDetected: boolean;
}

const initialState = {
  inRange: false,
  trainDetected: false
};

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case "IN_RANGE": {
      return { ...state, inRange: true };
    }
    case "NOT_IN_RANGE": {
      return { ...state, inRange: false };
    }
    default: {
      return state;
    }
  }
}

import React from "react";
import { useColorScheme } from "react-native-appearance";
import { Image, TouchableOpacity } from "react-native";
import * as WebBrowser from "expo-web-browser";

interface ICohubLogoProps {
  fourthClosed: boolean;
  chestnutClosed: boolean;
}

export default function CohubLogo({
  fourthClosed,
  chestnutClosed
}: ICohubLogoProps) {
  const colorMode = useColorScheme();

  const openLink = () => {
    WebBrowser.openBrowserAsync("https://cohub.com/train-spotter");
  };

  const logoSource = () => {
    if (colorMode === "dark") {
      if (fourthClosed && !chestnutClosed) {
        return require("./assets/logos/cohub-logo-white-yellow.png");
      } else if (!fourthClosed && chestnutClosed) {
        return require("./assets/logos/cohub-logo-white-yellow.png");
      } else if (fourthClosed && chestnutClosed) {
        return require("./assets/logos/cohub-logo-white-red.png");
      } else {
        return require("./assets/logos/cohub-logo-white-green.png");
      }
    } else {
      if (fourthClosed && !chestnutClosed) {
        return require("./assets/logos/cohub-logo-yellow.png");
      } else if (!fourthClosed && chestnutClosed) {
        return require("./assets/logos/cohub-logo-yellow.png");
      } else if (fourthClosed && chestnutClosed) {
        return require("./assets/logos/cohub-logo-red.png");
      } else {
        return require("./assets/logos/cohub-logo-green.png");
      }
    }
  };

  return (
    <TouchableOpacity onPress={openLink}>
      <Image source={logoSource()} style={{ width: 93.84, height: 24 }}></Image>
    </TouchableOpacity>
  );
}
